/* App.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App.authenticated {
  padding: 20px;
}

.app-content {
  max-width: 800px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.App-header {
  text-align: center;
  margin-bottom: 30px;
}

.App-header h1 {
  color: #333;
  font-size: 24px;
}

.question-label {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #87CEFA;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.radio-option:hover {
  background-color: #e6f7ff;
}

.radio-option input[type="radio"] {
  display: none;
}

.radio-option label {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.radio-option .radio-button {
  width: 24px;
  height: 24px;
  border: 2px solid #87CEFA;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-option input[type="radio"]:checked + label .radio-button {
  background-color: #87CEFA;
}

.radio-option input[type="radio"]:checked + label .radio-button::after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.nav-button {
  padding: 10px 20px;
  background-color: #4682B4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #5cacee;
}

.nav-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.progress-bar {
  height: 5px;
  background-color: #e0e0e0;
  margin-top: 20px;
  border-radius: 2.5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #87CEFA;
  transition: width 0.3s ease-in-out;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.w-24 {
  width: 6rem;
}

.h-24 {
  height: 6rem;
}

.bg-gray-200 {
  background-color: #e5e7eb;
}

.rounded-full {
  border-radius: 9999px;
}

.text-gray-500 {
  color: #6b7280;
}

.w-full {
  width: 100%;
}

.max-w-xs {
  max-width: 20rem;
}

.intro-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 2rem;
  text-align: left;
}

.intro-header {
  text-align: center;
  margin-bottom: 1rem;
}

.intro-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.intro-header p {
  font-size: 1.2rem;
  color: #666;
}

.intro-main {
  flex-grow: 1;
}

.intro-welcome, .intro-features, .intro-privacy {
  margin-bottom: 2rem;
}

.intro-welcome h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.intro-features h3, .intro-privacy h3 {
  font-size: 1.4rem;
  color: #444;
  margin-bottom: 0.5rem;
}

.intro-section-header {
  font-size: 1.4rem;
  color: #444;
  margin-bottom: 0.5rem;
}

.intro-features ul {
  list-style-type: none;
  padding-left: 1rem;
}

.intro-features li {
  margin-bottom: 0.5rem;
  position: relative;
}

.intro-features li::before {
  content: '✓';
  color: #87CEFA;
  position: absolute;
  left: -1rem;
}

.intro-footer {
  text-align: center;
}

.intro-disclaimer {
  font-size: 0.9rem;
  color: #888;
  margin-top: 1rem;
}

.start-button {
  padding: 10px 20px;
  background-color: #4682B4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #5cacee;
}

.start-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.text-primary {
  color: #3b82f6; /* Adjust this to match your primary color */
}

.bg-primary {
  background-color: #3b82f6; /* Adjust this to match your primary color */
}

.hover\:bg-primary-dark:hover {
  background-color: #2563eb; /* A darker shade of the primary color */
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}